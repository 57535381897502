<template>
  <div class="background--dust">
    <div class="background--dust__mask"></div>
    <canvas class="background--dust__canvas" ref="canvas"></canvas>
  </div>
</template>

<script>
import Viewport from '@/mixins/Viewport'
import Platform from '@/platform'
import RAF from '@/utils/RAF'

export default {
  name: 'BackgroundDust',

  mixins: [Viewport],

  mounted () {
    this._config = {
      particlesNumber: (Platform.phone) ? 2000 : 3000
    }

    this._canvas = this.$refs.canvas
    this._context = this._canvas.getContext('2d')

    this._canvas.width = (Platform.phone) ? this.viewPort.width : this.viewPort.width * 0.6
    this._canvas.height = this.viewPort.height * 0.8

    this._particles = []

    this.particlesInit()
    this.particlesUpdate()
    this.particlesDraw()

    RAF.add(this.update)
  },

  methods: {
    resetParticles () {
      this._particles = []
      this.particlesInit()
    },

    particlesInit () {
      let n = this._config.particlesNumber

      for (let i = 0; i < n; i++) {
        this._particles.push({
          opacity: 1,
          radius: Math.random() * 0.9,
          velocity: {
            x: 0.5 * Math.random() - 0.5,
            y: 0.5 * Math.random() - 0.5
          },
          position: {
            x: Math.random() * this._canvas.width,
            y: Math.random() * this._canvas.height
          }
        })
      }
    },

    particlesDraw () {
      let n = this._config.particlesNumber

      this._context.fillStyle = '#636362'

      for (let i = 0; i < n; i++) {
        let p = this._particles[i]

        this._context.beginPath()
        this._context.arc(p.position.x, p.position.y, p.radius, 0, Math.PI * 2, false)
        this._context.closePath()
        this._context.fill()
      }
    },

    particlesUpdate () {
      for (let i = 0; i < this._config.particlesNumber; i++) {
        let p = this._particles[i]

        let ms = 0.5

        p.position.x += p.velocity.x * ms
        p.position.y += p.velocity.y * ms

        if (p.position.x - p.radius > this._canvas.width) {
          p.position.x = -p.radius
          p.position.y = Math.random() * this._canvas.height
        } else if (p.position.x + p.radius < 0) {
          p.position.x = this._canvas.width + p.radius
          p.position.y = Math.random() * this._canvas.height
        }

        if (p.position.y - p.radius > this._canvas.height) {
          p.position.y = -p.radius
          p.position.x = Math.random() * this._canvas.width
        } else if (p.position.y + p.radius < 0) {
          p.position.y = this._canvas.height + p.radius
          p.position.x = Math.random() * this._canvas.width
        }
      }
    },

    update () {
      this._context.clearRect(0, 0, this._canvas.width, this._canvas.height)

      this.particlesUpdate()
      this.particlesDraw()
    }
  },

  watch: {
    viewPort () {
      let w = (Platform.phone) ? this.viewPort.width : this.viewPort.width * 0.6
      let h = this.viewPort.height * 0.8

      if (this._canvas.width !== w || this._canvas.height !== h) {
        this._canvas.width = w
        this._canvas.height = h

        this.resetParticles()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'variables';

.background--dust {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;

  margin: auto;

  width: 100%;
  height: 100%;

  pointer-events: none;
  z-index: -1;

  .background--dust__canvas,
  .background--dust__mask {
    position: absolute;

    left: 0;
    right: 0;

    margin: auto;
  }

  .background--dust__mask {
    background-image: url('/static/img/mask.png');
    background-size: cover;
    background-position-x: 45%;

    width: 100%;
    height: 100%;

    z-index: 2;

    @include breakpoint($xs) {
      background-position-x: 50%;
    }
  }
}
</style>
