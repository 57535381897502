<template>
  <div class="noise--overlay"></div>
</template>

<script>
export default {
  name: 'NoiseOverlay'
}
</script>

<style scoped lang="scss">
@import 'variables';

.noise--overlay {
  background: url('/static/gif/noise.gif');
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
  opacity: 0.05;
}
</style>
