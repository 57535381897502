import axios from 'axios'

class SMSAPI {
  constructor () {
    this._getConf = 'https://tflxlts9h5.execute-api.eu-central-1.amazonaws.com/api/stage?action=getConf'
    this._resendSMS = 'https://tflxlts9h5.execute-api.eu-central-1.amazonaws.com/api/stage?action=resendSMS'
    this._registerUser = 'https://tflxlts9h5.execute-api.eu-central-1.amazonaws.com/api/stage?action=registerUser'
    this._validateSMS = 'https://tflxlts9h5.execute-api.eu-central-1.amazonaws.com/api/stage?action=confirmSMSCode'
  }

  getConfirmation (next) {
    // let query = encodeURIComponent(JSON.stringify(data))
    axios.post(this._getConf).then(response => {
      next(response.data)
    }).catch(error => {
      next(error)
      console.log(error)
    })
  }

  registerUser (data, next) {
    // let query = encodeURIComponent(JSON.stringify(data))
    axios.post(this._registerUser, data).then(response => {
      next(response.data)
    }).catch(error => {
      next(error)
      console.log(error)
    })
  }

  resendSMS (data, next) {
    // let query = encodeURIComponent(JSON.stringify(data))
    axios.post(this._resendSMS, data).then(response => {
      next(response.data)
    }).catch(error => {
      next(error)
      console.log(error)
    })
  }

  validateSMS (data, next) {
    // let query = encodeURIComponent(JSON.stringify(data))
    axios.post(this._validateSMS, data).then(response => {
      next(response.data)
    }).catch(error => {
      next(error)
      console.log(error)
    })
  }
}

export default new SMSAPI()
