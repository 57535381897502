<template>
  <div class="animated--hand" :class="status">
    <div class="animated--hand__canvas--wrapper" v-show="showHand" ref="canvasWrapper"></div>

    <div class="animated--hand__bottom--mask"></div>
  </div>
</template>

<script>
// import Viewport from '@/mixins/Viewport'
import lottie from 'lottie-web'

export default {
  name: 'AnimatedHand',

  data () {
    return {
      status: 'animation__start',
      showHand: true
    }
  },

  mounted () {
    let lottieAnimation = lottie.loadAnimation({
      container: this.$refs.canvasWrapper,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: '/static/lottie/data.json'
    })

    lottieAnimation.addEventListener('complete', () => {
      this.status = 'animation__complete'
    })

    this.$events.on('input:focus', () => {
      this.showHand = false
    })

    this.$events.on('input:blur', () => {
      this.showHand = true
    })
  }
}
</script>

<style scoped lang="scss">
@import 'variables';

.animated--hand {
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  .animated--hand__bottom--mask {
    background-image: url('/static/img/mask-bottom.png');
    background-size: 1px 200px;
    background-repeat: repeat-x;
    width: 100%;
    height: 200px;

    position: fixed;
    bottom: 0;
    left: 0;
  }

  .animated--hand__canvas--wrapper {
    position: absolute;
    height: 90%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;

    @include breakpoint($xs) {
      width: 200%;
      left: -50%;
      height: auto;
    }

    @include breakpoint($mobile-height-xs) {
      width: 160%;
      left: -30%;
      height: auto;
    }
  }

  // &.animation__complete {
  //   .animated--hand__canvas--wrapper {
  //     transform: translateY(-100px);
  //   }
  // }
}
</style>
