<template>
  <div class="checkbox--component">
    <div class="checkbox--square" @click="onClick" :class="{'checkbox__filled': value}">
      <span>✓</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    onClick () {
      this.$emit('update:value', !this.value)
    }
  }
}
</script>

<style scoped lang="scss">
@import "variables";

.checkbox--component {
  user-select: none;
}

.error {
  .checkbox--square {
    border: solid 1px $c-blackmirror-red !important;
  }
}

.checkbox--component .checkbox--square {
  width: 15px;
  height: 15px;
  border: solid 1px $c-blackmirror-blue;
  display: inline-block;
  pointer-events: all;
  cursor: pointer;

  transition: background-color 0.1s;

  &:hover {
    background-color: color-alpha($c-blackmirror-blue, 0.2);
  }

  span {
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    font-weight: bold;
    color: $c-black;
    opacity: 0;

    transition: opacity 0.1s;
  }

  &.checkbox__filled {
    background-color: $c-blackmirror-blue;

    span {
      opacity: 1;
    }
  }
}
</style>
