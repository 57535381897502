<template>
  <article class="form-page">
    <!-- Segment Pixel - Netflix | BlackMirror | LandingPage - DO NOT MODIFY -->
    <img src="https://secure.adnxs.com/seg?add=16182337&t=2" width="1" height="1" />
    <!-- End of Segment Pixel -->
    <!-- Segment Pixel - Netflix | BlackMirror | Converters - DO NOT MODIFY -->
    <img v-if="state === 'success'" src="https://secure.adnxs.com/seg?add=16182346&t=2" width="1" height="1" />
    <!-- End of Segment Pixel -->
    <BackgroundDust />
    <NoiseOverlay />
    <AnimatedHand />

    <div class="form-container" :class="{'active': state !== 'intro', 'code': state === 'message', 'closed': !formIsOpen}">
      <div class="form-container__copy" v-show="state === 'form'">
        <h4 class="medium blue" ref="copy1">IL PEGGIOR GIOCO DI SEMPRE È LA REALTÀ</h4>
        <h4 v-if="!formIsOpen" class="uppercase" ref="copy2">This is not a Game Over. Anche se abbiamo raggiunto il numero massimo di registrazioni possibili,<br>potrai comunque partecipare al gioco: il 16 gennaio collegati al profilo Instagram di Netflix.</h4>
        <h4 v-if="!secondvisit && formIsOpen" class="uppercase" ref="copy2">Per entrare fin da subito nel vivo dell’azione,<br>lasciaci il tuo numero e ti richiameremo.</h4>
        <h4 v-if="secondvisit && formIsOpen" class="uppercase" ref="copy2">Sei tornato finalmente, ti stavamo aspettando.<br>Chissà se ora porterai a termine la registrazione.</h4>
      </div>

      <div class="form-container__copy" v-show="state === 'message'">
        <h4 class="medium blue" ref="copy3">FIDARSI È BENE, NON FIDARSI È BLACK MIRROR.</h4>
        <h4 ref="copy4">Il numero che ci hai lasciato, è davvero il tuo?<br>Confermalo inserendo il codice che ti abbiamo inviato via SMS.</h4>
      </div>

      <div v-if="formIsOpen" class="form-inputs">
        <transition-group>
          <div v-show="state === 'form'" :class="[{ 'form-loading': loadingApi}]" key="form" class="form-wrapper">
            <form class="form-phone" id="data" @submit="checkForm">
              <p :class="[{ 'error': errorscode.indexOf(2) !== -1 || errorscode.indexOf(4) !== -1 || errorscode.indexOf(6) !== -1}, 'form-input']">
                <span class="icon--wrapper" ref="phone1">
                  <PhoneIcon class="phone-icon" />
                </span>
                <input class="form-field-phone" @focus="onFocus" @blur="onBlur" @focusout="onBlur" ref="input1" placeholder="INSERISCI NUMERO" type="number" name="phone" id="phone" v-model="phone">
              </p>
              <p :class="[{ 'error': errorscode.indexOf(3) !== -1 || errorscode.indexOf(5) !== -1 || errorscode.indexOf(6) !== -1}, 'form-input']">
                <input class="form-field-phone confirm" @focus="onFocus" @blur="onBlur" @focusout="onBlur" ref="input2" placeholder="CONFERMA NUMERO" type="number" name="phoneconfirm" id="phoneconfirm" v-model="phoneconfirm">
              </p>
              <p :class="[{ 'error': errorscode.indexOf(7) !== -1}, 'form-input privacy']" ref="checkbox">
                <Checkbox :value.sync="privacyfirst" />
              </p>
              <div :class="[{ 'error': errorscode.indexOf(7) !== -1}, 'privacy-text']" ref="checkboxText">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
              <p ref="cta">
                <input class="form-button" type="submit" value="Invia">
              </p>
            </form>
            <div v-show="errorscode.indexOf(4) !== -1" class="number-error">Il numero inserito non è nel formato corretto.<br>Riprova.</div>
            <div v-show="errorscode.indexOf(8) !== -1" class="number-error">Il tuo numero è già stato registrato.<br>Ci fa piacere vederti così motivato a partecipare.</div>
            <div v-show="errorscode.indexOf(7) !== -1" class="number-error privacy">Non ci sfugge nulla:<br>ti sei dimenticato di dare il consenso al trattamento dei tuoi dati.</div>
          </div>
          <div v-show="state === 'message'" :class="[{ 'form-loading': loadingApi}]" key="message" class="form-wrapper">
            <form id="sms" @submit="checkSMSCode">
              <p :class="[{ 'error': smserrorscode.indexOf(1) !== -1 || smserrorscode.indexOf(2) !== -1}, 'form-input']">
                <label for="smscode" class="form-field-label">BM</label>
                <input class="form-field-phone code" placeholder="INSERISCI CODICE" type="number" name="smscode" id="smscode" v-model="smscode">
              </p>
              <p ref="cta2">
                <input class="form-button" type="submit" value="Invia">
              </p>
              <p class="resend-button" @click="onResend">Rimanda SMS</p>
            </form>
            <div v-if="smserrorscode.indexOf(2) !== -1" class="number-error sms">Il codice inserito non è corretto.<br>Se hai cercato di ingannare Black Mirror, ti è andata male. Riprova.</div>
          </div>
          <div v-show="state === 'success'" key="success" class="form-wrapper form-wrapper__success">
            <h4 class="medium blue" ref="copy5">IL PEGGIOR GIOCO DI SEMPRE È LA REALTÀ</h4>
            <h4 ref="copy6">Congratulazioni, ora fai parte del gioco più nero di sempre.<br>Tieniti pronto, perché presto riceverai una nostra telefonata.</h4>
          </div>
        </transition-group>
      </div>

      <div v-show="loadingApi" class="loading">
        <img src="static/gif/loading.gif">
      </div>
    </div>
  </article>
</template>

<script>
import FirePrerenderEvent from '@/mixins/FirePrerenderEvent'
import SMSAPI from '@/services/SMSAPI'
import BackgroundDust from '@/molecules/BackgroundDust'
import NoiseOverlay from '@/atoms/NoiseOverlay'
import AnimatedHand from '@/atoms/AnimatedHand'
import Checkbox from '@/atoms/Checkbox'
import PhoneIcon from '@/assets/svg/ic-phone.svg'
import Platform from '@/platform'

import { TimelineLite } from 'gsap'

export default {
  name: 'Form',

  mixins: [FirePrerenderEvent],

  props: {
    secondvisit: {
      type: Boolean,
      required: false
    }
  },

  components: {
    BackgroundDust,
    NoiseOverlay,
    Checkbox,
    AnimatedHand,
    PhoneIcon
  },

  data: function () {
    return {
      errorscode: [],
      phone: null,
      phoneconfirm: null,
      privacyfirst: false,
      smserrorscode: [],
      smscode: null,
      state: 'intro',
      loadingApi: false,
      formIsOpen: true
    }
  },

  mounted: function () {
    SMSAPI.getConfirmation(this.startAnimation)
  },

  methods: {
    startAnimation (result) {
      if (result && result.data && result.data.smsEnabled === false) {
        this.formIsOpen = false
      }
      // this.formIsOpen = false
      setTimeout(() => {
        this.state = 'form'

        let elements = {
          copy: [this.$refs.copy1, this.$refs.copy2],
          form: [this.$refs.phone1, this.$refs.input1, this.$refs.input2, this.$refs.checkbox, this.$refs.checkboxText, this.$refs.cta]
        }

        for (let i = 0; i < elements.copy.length; i++) {
          let sp = new window.SplitText(elements.copy[i], { type: 'chars, words' })
          let tl = new TimelineLite({
            delay: (i / 2) + 0.1
          })

          let numChars = sp.chars.length

          for (let i = 0; i < numChars; i++) {
            tl.fromTo(sp.chars[i], 0.7, {
              opacity: 0,
              filter: 'blur(3px)',
              textShadow: '0px 0px 7px 0px rgba(198,230,251,0.76)'
            }, {
              opacity: 1,
              filter: 'blur(0px)',
              textShadow: '0px 0px 7px 0px rgba(198,230,251,0.76)'
            }, Math.random() * 0.7)
          }
        }

        if (this.formIsOpen) {
          let tlForm = new TimelineLite({
            delay: 1.2
          })

          for (let i = 0; i < elements.form.length; i++) {
            console.log(elements.form[i])
            tlForm.fromTo(elements.form[i], 0.7, {
              opacity: 0
            }, {
              opacity: 1
            }, 0.1 * i)
          }
        }
      }, 2500)
    },
    onFocus () {
      if (!Platform.mobile || !Platform.android) {
        return
      }

      this.$events.emit('input:focus')
    },

    onBlur () {
      if (!Platform.mobile || !Platform.android) {
        return
      }

      this.$events.emit('input:blur')
    },

    checkForm: function (e) {
      e.preventDefault()

      this.errors = []
      this.errorscode = []
      if (!this.phone) {
        this.errorscode.push(2)
      }
      if (!this.phoneconfirm) {
        this.errorscode.push(3)
      }

      if (this.phone && this.phone.length < 9) {
        this.errorscode.push(4)
      }
      if (this.phoneconfirm && this.phoneconfirm.length < 9) {
        this.errorscode.push(5)
      }
      if (this.phoneconfirm && this.phone && this.phoneconfirm !== this.phone) {
        this.errorscode.push(6)
      }
      if (!this.privacyfirst) {
        this.errorscode.push(7)
      }
      if (this.errorscode.length === 0) {
        console.log('form correct')
        this.submitForm()
      } else {
        console.log('form wrong')
      }
    },
    // SUBMIT FORM
    submitForm: function () {
      this.loadingApi = true
      let submitPhone = this.phone
      if (submitPhone.indexOf('0039') === -1 && submitPhone.length < 11) {
        submitPhone = '0039' + this.phone
      }
      SMSAPI.registerUser({
        name: 'user',
        phone: submitPhone,
        privacy1: this.privacyfirst,
        privacy2: false
      }, this.onSubmitForm)
    },
    onSubmitForm: function (result) {
      console.log(result)
      this.loadingApi = false
      if (result.err) {
        this.errorscode.push(8)
      } else if (!result.err) {
        this.state = 'message'

        let elements = {
          copy: [this.$refs.copy3, this.$refs.copy4],
          form: [this.$refs.phone1, this.$refs.input1, this.$refs.phone2, this.$refs.input2, this.$refs.checkbox, this.$refs.checkboxText, this.$refs.cta2]
        }

        for (let i = 0; i < elements.copy.length; i++) {
          let sp = new window.SplitText(elements.copy[i], { type: 'chars, words' })
          let tl = new TimelineLite({
            delay: (i / 2) + 0.1
          })

          let numChars = sp.chars.length

          for (let i = 0; i < numChars; i++) {
            tl.fromTo(sp.chars[i], 0.7, {
              opacity: 0,
              filter: 'blur(3px)',
              textShadow: '0px 0px 7px 0px rgba(198,230,251,0.76)'
            }, {
              opacity: 1,
              filter: 'blur(0px)',
              textShadow: '0px 0px 7px 0px rgba(198,230,251,0.76)'
            }, Math.random() * 0.7)
          }
        }
      }
    },
    checkSMSCode: function (e) {
      e.preventDefault()
      this.smserrors = []
      this.smserrorscode = []
      if (!this.smscode) {
        this.smserrorscode.push(1)
      }
      if (this.smserrorscode.length === 0) {
        console.log('sms code correct')
        this.submitSMSCode()
      } else {
        console.log('sms code wrong')
      }
    },
    // RESEND SMS
    onResend: function () {
      this.loadingApi = true
      let submitPhone = this.phone
      if (submitPhone.indexOf('0039') === -1 && submitPhone.length < 11) {
        submitPhone = '0039' + this.phone
      }
      SMSAPI.resendSMS({
        phone: submitPhone
      }, this.onSubmitResend)
    },
    onSubmitResend: function (result) {
      this.loadingApi = false
      console.log('onSMSResent', result)
    },
    // SMS CODE
    submitSMSCode: function () {
      this.loadingApi = true
      let submitPhone = this.phone
      if (submitPhone.indexOf('0039') === -1 && submitPhone.length < 11) {
        submitPhone = '0039' + this.phone
      }
      SMSAPI.validateSMS({
        phone: submitPhone,
        code: this.smscode
      }, this.onSubmitSMSCode)
    },
    onSubmitSMSCode: function (result) {
      this.loadingApi = false
      console.log(result)
      if (result.err) {
        this.smserrorscode.push(2)
      } else if (!result.err) {
        this.state = 'success'

        let elements = {
          copy: [this.$refs.copy5, this.$refs.copy6],
          form: [this.$refs.phone1, this.$refs.input1, this.$refs.phone2, this.$refs.input2, this.$refs.checkbox, this.$refs.checkboxText]
        }

        for (let i = 0; i < elements.copy.length; i++) {
          let sp = new window.SplitText(elements.copy[i], { type: 'chars, words' })
          let tl = new TimelineLite({
            delay: (i / 2) + 0.1
          })

          let numChars = sp.chars.length

          for (let i = 0; i < numChars; i++) {
            tl.fromTo(sp.chars[i], 0.7, {
              opacity: 0,
              filter: 'blur(3px)',
              textShadow: '0px 0px 7px 0px rgba(198,230,251,0.76)'
            }, {
              opacity: 1,
              filter: 'blur(0px)',
              textShadow: '0px 0px 7px 0px rgba(198,230,251,0.76)'
            }, Math.random() * 0.7)
          }
        }
      }
    }
  },

  watch: {
    smscode () {
      if (this.smscode.length >= 4) {
        this.smscode = this.smscode.substring(0, 4)
      }
    },
    phone () {
      if (this.phone.length >= 14) {
        this.phone = this.phone.substring(0, 14)
      }
    },
    phoneconfirm () {
      if (this.phoneconfirm.length >= 14) {
        this.phoneconfirm = this.phoneconfirm.substring(0, 14)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'variables';

.form-page {
  text-align: center;
  padding: 0;
  background-image: url('/static/img/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: $c-black;
  height: 100vh;

  overflow: hidden;

  h4 {
    margin: 10px;
    text-transform: uppercase;

    &.medium {
      font-weight: bold;
    }
  }
}
.form-loading {
  opacity: 0.3;
  pointer-events: none;
}

.form-inputs {
  height: 175px;
  position: relative;
  margin-top: 60px;

  @include breakpoint($mlg-down) {
    margin-top: 20px;
  }

  @include breakpoint($xs) {
    margin-top: 25px;
  }

  @include breakpoint($desktop-height-xs) {
    margin-top: 0;
  }

  html.ie11 & {
    height: 195px;
  }
}

.form-wrapper {
  position: absolute;

  top: 0;
  right: 0;
  display: table;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -20px;

  &.form-wrapper__success {
    margin-left: 0;
  }

  .number-error {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: 220px;
    margin-top: 15px;
    text-align: left;
    font-size: 11px;
    line-height: 12px;
    width: 330px;
    color: $c-blackmirror-red;

    @include breakpoint($xs) {
      text-align: center;
      width: 280px;
      font-size: 9px;
      margin-left: -120px;
      margin-top: -20px;
    }

    &.sms {
      margin-top: 25px;

      @include breakpoint($xs) {
        margin-top: -20px;
      }
    }

    &.privacy {
      margin-top: 100px;

      @include breakpoint($xs) {
        margin-top: 85px;
      }
    }
  }
}

.icon--wrapper {
  html.ie11 & {
    width: 30px;
    height: 30px;
    display: inline-block;
  }
}

.form-container {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  transform: translateY(80px);

  transition: transform 1s $ease-out-quad;

  @include breakpoint($xs) {
    bottom: 40px;
  }

  @include breakpoint($desktop-height-xs) {
    bottom: 20px;
  }

  &.closed {
    bottom: 150px;

    @include breakpoint($xs) {
      bottom: 70px;
    }
  }

  &.active {
    transform: translateY(0px);
  }

  &.code {
    @include breakpoint($xs) {
      bottom: 10px;
    }
  }
}

input.form-button {
  background-image: url("/static/img/button-bg-submit.png");
  width: 183px;
  height: 40px;
  background-size: cover;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: $font-netlix;
  cursor: pointer;
  background-color: transparent;
  transition: background-image 0.2s ease-out, color 0.2s ease-out;
  margin-left: 40px;
  border-radius: 0;

  &:hover {
    background-image: url("/static/img/button-bg-submit-rollover.png");
    color: $c-white;
  }

  @include breakpoint($xs) {
    font-size: 10px;
    width: 143px;
    height: 25px;
  }
}
.resend-button {
  color: $c-blackmirror-blue;
  font-size: 12px;
  // text-decoration: underline;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 40px;
}

.form-input {
  @include breakpoint($xs) {
    margin: 5px 0;
  }
  .form-field-label {
    font-size: 14px;
    font-weight: 500;
    color: $c-blackmirror-blue;
    margin-right: 10px;
  }
  .form-field-phone {
    text-align: center;
    border: 2px $c-blackmirror-blue solid;
    box-shadow: 0px 0px 7px 0px rgba(198,230,251,0.76);
    background-color: transparent;
    height: 25px;
    width: 350px;
    color: $c-blackmirror-grey;
    font-size: 14px;
    outline: none;
    border-radius: 0;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
    &:-webkit-autofill {
      background-color: transparent !important;
    }

    @include breakpoint($xs) {
      width: 220px;
      height: 20px;
      font-size: 12px;
    }

    &.confirm {
      margin-left: 45px;

      html.ie11 & {
        margin-left: 34px;
      }
    }

    &.code {
      margin-top: 20px;
      margin-bottom: 20px;

      @include breakpoint($xs) {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .form-field-privacy {
    width: 20px;
    height: 20px;
    background-color: transparent;
  }

  &.privacy {
    font-size: 7px;
    width: 15px;
    display: inline-block;
    vertical-align: middle;

    @include breakpoint($xs) {
      margin-top: 35px;
    }
  }

  .phone-icon {
    vertical-align: middle;
    height: 40px;
    fill: $c-blackmirror-blue;
  }

  &.error {
    color: $c-blackmirror-red;
    .form-field-phone {
      color: $c-blackmirror-red;
      border: 1px $c-blackmirror-red solid;
      box-shadow: 0px 0px 7px 0px $c-blackmirror-red;
    }
    .phone-icon {
      fill: $c-blackmirror-red;
    }
    .form-field-label {
      color: $c-blackmirror-red;
    }

    @include breakpoint($xs) {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &.privacy {
      @include breakpoint($xs) {
        margin-top: 35px;
        margin-bottom: 0;
      }
    }
  }
}

.privacy-text {
  font-size: 9px;
  text-align: left;
  width: 325px;
  float: right;
  margin-right: 6px;
  margin-top: 7px;
  margin-left: -31px;

  @include breakpoint($xs) {
    width: 220px;
    margin-right: -11px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &.error {
    color: $c-red;
  }
}

.loading {
  z-index: 2;
  transform: translate(-50%, -100px);
  position: absolute;
  left: 50%;
  bottom: 0;
}
</style>
